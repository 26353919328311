










































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import { formatDateRange, formatLongDate } from '@/utils/date_time';
import ExpandableText from '@/components/ExpandableText.vue';
import ModalQRCode from '@/components/Mobile/ModalQRCode.vue';
import Place from '@/dtos/place';
import { links } from '@/utils/link-detector';
import QRLinks from '@/components/Mobile/QRLinks.vue';
import { formatString } from '@/utils/string-formatter';
import ShareIcon from '@/assets/mobile/icons/share-icon.svg';
import DeleteIcon from '@/assets/mobile/icons/delete-icon.svg';
import EditIcon from '@/assets/mobile/icons/edit-icon.svg';
import DateTimeIcon from '@/assets/mobile/icons/datetime-icon.svg';
import LocationIcon from '@/assets/mobile/icons/location-icon.svg';
import MembersIcon from '@/assets/mobile/icons/members-icon.svg';
import RideeEvent from './event';
import EventAttentiveUsers from '@/event/EventInterest.vue';

export default Vue.extend({
  components: {
    Scaffold,
    ExpandableText,
    ModalQRCode,
    QRLinks,
    ShareIcon,
    DeleteIcon,
    EditIcon,
    DateTimeIcon,
    LocationIcon,
    MembersIcon,
    EventAttentiveUsers,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      qrText: null as string | null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      isInteresting: 'engagement/isEventInetersting',
    }),
    isEventInteresting(): boolean {
      return this.isInteresting(this.eventId);
    },
    event(): RideeEvent {
      return this.$store.getters['event/current'];
    },
    isMyEvent(): boolean {
      return this.event.organizerId == this.user.data.uid;
    },
    isPastEvent(): boolean {
      return this.event.startDateTime < new Date();
    },
  },
  async mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('toggleLoading', true);
    await Promise.all([
      dispatch('event/bindEvent', this.eventId),
      dispatch('engagement/bindEngagement'),
    ]);
    dispatch('toggleLoading', false);
  },
  methods: {
    descriptionLinks() {
      return links(this.event.description, [], []);
    },
    formatDate(from: Date, to?: Date): string {
      if (!to) return formatLongDate(from);
      return formatDateRange(from, to);
    },
    openMaps(place: Place) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${place.latitude},${place.longitude}`
      );
    },
    openQR(text: string) {
      this.qrText = text;
    },
    toggleInterest() {
      this.$store.dispatch('engagement/toggleCurrentEventIneterest');
    },
    edit() {
      this.$router.push({
        name: 'Mobile Event Edit',
        params: { eventId: this.eventId },
      });
    },
    remove() {
      if (confirm('Möchtest du dieses Event wirklich löschen?')) {
        this.$store
          .dispatch('event/delete', this.eventId)
          .then(() => this.$router.push({ name: 'Mobile Discover' }));
      }
    },
    share(): void {
      const t = this.event;
      if (!t) return;

      const title = t.title;
      const message = formatString(
        this.$t('event.sharing.text') as string,
        t.title,
        formatLongDate(t.startDateTime),
        t.location.description
      );
      const url = ['https://', window.location.hostname, '/events/', t.id].join(
        ''
      );
      navigator.share({ title: title, text: message, url: url });
    },
  },
});
